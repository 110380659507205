import http from "./http"

// 导出订单excel
export function exportOrderExcel(data){
    return http.blobGet("/api/mall/order/export/"+data.orderId)
}

/*短信验证码Api接口*/
// 发送验证码
export function sendValidCode(data){
    return http.post("/api/sendSms",data)
}

/*网站登录注册接口*/
// 注册
export function registerUser(data){
    return http.post2("/api/register",data)
}
// 退出登录
export function logout(data){
    return http.post("/api/logout",data)
}
// 密码登录
export function loginByPwd(data){
    return http.post2("/api/loginByPwd",data)
}
// 短信验证码登录
export function loginByCode(data){
    return http.post("/api/loginByCode",data)
}
// 忘记密码
export function forgetPwd(data){
    return http.post2("/api/forgetPwd",data)
}
// 校验密码
export function checkUserPwd(data){
    return http.get("/api/user/checkUserPwd",data)
}

/*省市区接口*/
// 新增省市区
export function addCity(data){
    return http.post("/system/city",data)
}
// 修改省市区
export function editCity(data){
    return http.put("/system/city",data)
}
// 获取省市区详细信息
export function getCityInfo(data){
    return http.get("/system/city/"+data.id,data)
}
// 删除省市区
export function deleteCity(data){
    return http.delete("/system/city/"+data.id,data)
}
// 查询省市区列表
export function getCityList(data){
    return http.get("/system/city/list",data)
}
// 批量删除省市区
export function deleteAllCity(data){
    return http.delete("/system/city/batch",data)
}

/*商品套餐接口*/
// 修改商品套餐
export function editPackage(data){
    return http.put("/mall/package",data)
}
// 新增商品套餐
export function addPackage(data){
    return http.post("/mall/package/saveOrUpdate",data)
}
// 获取商品套餐详细信息
export function getPackageInfo(data){
    return http.get("/mall/package/"+data.packageId,data)
}
// 删除商品套餐
export function deletePackage(data){
    return http.delete("/mall/package/"+data.packageId,data)
}
// 根据选择方式获取商品
export function queryModeGoods(data){
    return http.get("/mall/package/queryModeGoods",data)
}
// 查询商品套餐列表
export function getPackageList(data){
    return http.get("/mall/package/list",data)
}
// 网站套餐详情接口
export function getPackageDetail(data){
    return http.get("/api/mall/package/info",data)
}
// 优选套餐详情-相似套餐
export function getSimilarityPackageDetail(data){
    return http.get("/api/mall/package/similarity/"+data.goodsPackageId,data)
}
// 网站首页优选套餐
export function queryGoodsPackageList(data){
    return http.get("/api/mall/package/queryGoodsPackageList",data)
}
// 查询商品套餐列表(甄选套餐)
export function getPackageList_pick(data){
    return http.get("/api/mall/package/list",data)
}
// 批量删除商品套餐
export function deletePackageBatch(data){
    return http.delete("/mall/package/batch",data)
}
// 根据商品ID 查询相关套餐
export function getGoodsRelevantPackage(data){
    return http.get("/api/mall/package/goodsIdToPackageList",data)
}
// 根据商品套餐Id ,查询相关套餐项
export function getGoodsRelevantPackageItem(data){
    return http.get("/api/mall/package/queryGoodsPackageItemList",data)
}

/*全屋套餐接口*/
// 修改全屋套餐
export function editHousePackage(data){
    return http.put("/mall/housePackage",data)
}
// 新增全屋套餐
export function addHousePackage(data){
    return http.post("/mall/housePackage/saveOrUpdate",data)
}
// 获取全屋套餐详细信息
export function getHousePackageInfo(data){
    return http.get("/mall/housePackage/"+data.housePackageId,data)
}
// 删除全屋套餐
export function deleteHousePackage(data){
    return http.delete("/mall/housePackage/"+data.housePackageId,data)
}
// 查询商品套餐列表
export function getHousePackageList(data){
    return http.get("/mall/housePackage/list",data)
}
// 批量删除全屋套餐
export function deleteHousePackageBatch(data){
    return http.delete("/mall/housePackage/batch",data)
}
// 获取全屋套餐详细信息
export function getHousePackageInfo__(data){
    return http.get("/api/mall/housePackage/info",data)
}
// 优选套餐详情-相似套餐
export function getHousePackageSimilarity(data){
    return http.get("/api/mall/housePackage/similarity/"+data.housePackageId,data)
}
// 网站首页优选套餐（全屋定制）
export function getHousePackagePreferred(data){
    return http.get("/api/mall/housePackage/queryHousePackageList",data)
}
// 全屋套餐分页列表
export function getHousePackageList__(data){
    return http.get("/api/mall/housePackage/list",data)
}

/*商品接口*/
// 新增商品
export function addGoods(data){
    return http.post("/mall/goods",data)
}
// 修改商品
export function editGoods(data){
    return http.put("/mall/goods",data)
}
// 导入商品
export function importGoods(data){
    return http.post("/mall/goods/excel/import",data)
}
// 获取商品详细信息
export function getGoodsInfo(data){
    return http.get("/mall/goods/"+data.goodsId,data)
}
// 删除商品
export function deleteGoods(data){
    return http.delete("/mall/goods/"+data.goodsId,data)
}
// 查询商品列表
export function getGoodsList(data){
    return http.get("/mall/goods/list",data)
}
// 下载错误数据
export function downErrorExport(data){
    return http.get("/mall/goods/excel/export/error",data)
}
// 下载商品导入模板
export function downImportTemplate(data){
    return http.get("/mall/goods/excel/download/template",data)
}
// 批量删除商品
export function deleteGoodsBatch(data){
    return http.delete("/mall/goods/batch",data)
}

/*商品信息接口*/
// 新增商品信息
export function addGoodsInfo(data){
    return http.post("/mall/goodsInfo",data)
}
// 修改商品信息
export function editGoodsInfo(data){
    return http.put("/mall/goodsInfo",data)
}
// 查询商品信息详细信息
export function getGoodsInfo2(data){
    return http.get("/mall/goodsInfo/"+data.goodsInfoId,data)
}
// 删除商品信息
export function deleteGoodsInfo(data){
    return http.delete("/mall/goodsInfo/"+data.goodsInfoId,data)
}
// 查询商品信息列表
export function getGoodsInfoList(data){
    return http.get("/mall/goodsInfo/list",data)
}
// 批量删除商品信息
export function deleteGoodsInfoBatch(data){
    return http.delete("/mall/goodsInfo/batch",data)
}

/*商品组合接口*/
// 新增商品组合
export function addGoodsCombine(data){
    return http.post("/mall/goodsCombine",data)
}
// 修改商品组合
export function editGoodsCombine(data){
    return http.put("/mall/goodsCombine",data)
}
// 查询商品组合详细信息
export function getGoodsCombine(data){
    return http.get("/mall/goodsCombine/"+data.goodsCombineId,data)
}
// 删除商品组合
export function deleteGoodsCombine(data){
    return http.delete("/mall/goodsCombine/"+data.goodsCombineId,data)
}
// 查询商品组合列表
export function getGoodsCombineList(data){
    return http.get("/mall/goodsCombine/list",data)
}
// 获取商品组合详细信息（其中商品）
export function getGoodsCombineGoods(data){
    return http.get("/mall/goodsCombine/goods/"+data.goodsId,data)
}
// 批量删除商品组合
export function deleteGoodsCombineBatch(data){
    return http.delete("/mall/goodsCombine/batch",data)
}

/*买家秀接口*/
// 新增买家秀
export function addBuyersShow(data){
    return http.post("/mall/buyersShow",data)
}
// 修改买家秀
export function editBuyersShow(data){
    return http.put("/mall/buyersShow",data)
}
// 置顶买家秀
export function topBuyersShow(data){
    return http.put("/mall/buyersShow/change/top/"+data.buyersShowId,data)
}
// 设置状态
export function statusBuyersShow(data){
    return http.put("/mall/buyersShow/change/status/"+data.buyersShowId,data)
}
// 买家秀设为热门
export function hotBuyersShow(data){
    return http.put("/mall/buyersShow/change/hot/"+data.buyersShowId,data)
}
// 获取买家秀详细信息
export function getBuyersShowInfo(data){
    return http.get("/mall/buyersShow/"+data.buyersShowId,data)
}
// 删除买家秀
export function deleteBuyersShow(data){
    return http.delete("/mall/buyersShow/"+data.buyersShowId,data)
}
// 查询买家秀列表
export function getBuyersShowList(data){
    return http.get("/mall/buyersShow/list",data)
}
// 批量删除买家秀
export function deleteBuyersShowList(data){
    return http.delete("/mall/buyersShow/batch",data)
}

/*商家接口*/
// 新增商家
export function addBusiness(data){
    return http.post("/mall/business",data)
}
// 修改商家
export function editBusiness(data){
    return http.put("/mall/business",data)
}
// 获取商家详细信息
export function getBusinessInfo(data){
    return http.get("/mall/business/"+data.businessId,data)
}
// 删除商家
export function deleteBusiness(data){
    return http.delete("/mall/business/"+data.businessId,data)
}
// 查询商家列表
export function getBusinesslist(data){
    return http.get("/mall/business/list",data)
}
// 批量删除商家
export function deleteBusinessBatch(data){
    return http.delete("/mall/business/batch",data)
}

/*购物车接口*/
// 修改【购物车商品数量】
export function editCartNum(data){
    // return http.put("/api/mall/cart",data)
    return http.put2("/api/mall/cart",data)
}
// 新增【购物车】
export function saveShoppingCart(data){
    return http.post2("/api/mall/cart/saveShoppingCart",data)
}
// 批量新增【购物车】
export function saveShoppingCartBatch(data){
    return http.post2("/api/mall/cart/saveShoppingCartList",data)
}
// 批量删除【购物车】
export function deleteCartBatch(data){
    return http.post2("/api/mall/cart/batch",data)
}
// 查询【购物车】列表
export function getCartList(data){
    return http.get("/api/mall/cart/getShoppingCartList",data)
}
// 删除【购物车】
export function deleteCart(data){
    return http.delete("/api/mall/cart/delete/"+data.shoppingCartId)
}

/*商品售卖配置接口*/
// 新增商品售卖配置
export function addSetting(data){
    return http.post("/system/setting/saveOrUpdate",data)
}
// 获取商品售卖配置详细信息
export function getSalesSettingInfo(data){
    return http.get("/system/setting/"+data.salesSettingId,data)
}
// 查询商品售卖配置列表
export function getSettingList(data){
    return http.get("/system/setting/list",data)
}

/*直播间接口*/
// 新增直播间
export function addRoom(data){
    return http.post("/mall/room/saveOrUpdate",data)
}
// 获取【直播间】详细信息
export function getRoomInfo(data){
    return http.get("/mall/room/"+data.liveCastId,data)
}
// 删除【直播间】
export function deleteRoom(data){
    return http.delete("/mall/room/"+data.liveCastId,data)
}
// 查询【直播间】列表
export function getRoomPage(data){
    return http.get("/mall/room/page",data)
}
// 直播间设置推广
export function roomSetShow(data){
    return http.get("/mall/room/liveShow/"+data.liveCastId,data)
}
// 直播间设置取消推广
export function roomSetHide(data){
    return http.get("/mall/room/liveHide"+data.liveCastId,data)
}
// 查询【直播间】列表
export function getRoomList(data){
    return http.get("/api/mall/room/list",data)
}
// 批量删除直播间
export function deleteRoomBatch(data){
    return http.delete("/mall/room/batch",data)
}

/*商品字段接口*/
// 查询商品字段列表
export function getGoodsFieldList(data){
    return http.get("/mall/goods-field/list",data)
}

/*【购物车】接口*/
// 获取【购物车】详细信息
export function getShoppingCartInfo(data){
    return http.get("/mall/cart/"+data.shoppingCartId,data)
}
// 删除【购物车】
export function deleteShoppingCart(data){
    return http.delete("/mall/cart/"+data.shoppingCartId,data)
}
// 查询【购物车】列表
export function getCartList__(data){
    return http.get("/mall/cart/list",data)
}
// 批量删除【购物车】
export function deleteCartBatch__(data){
    return http.post("/mall/cart/batch",data)
}

/*用户接口*/
// 获取用户信息
export function getUserInfo(data){
    return http.get("/api/user/getInfo",data)
}
// 修改用户信息
export function editUserInfo(data){
    // return http.get("/api/user/updateInfo",data)
    return http.post2("/api/user/updateInfo",data)
}
// 获取商家子账号列表
export function getSubUserList(data){
    return http.get("/api/user/getSubUserList",data)
}
// 删除商家子账号接口
export function delSubUser(data){
    return http.get("/api/user/deleteSubUser/"+data.subUserId)
}


/*用户行为Api接口*/
// 分享
export function actionShare(data){
    return http.get("/api/user/action/share",data)
}
// 点赞
export function actionLike(data){
    return http.get("/api/user/action/like",data)
}
// 收藏
export function actionCollect(data){
    return http.get("/api/user/action/collect",data)
}
// 取消点赞
export function actionCancelLike(data){
    return http.get("/api/user/action/cancelLike",data)
}
// 取消收藏
export function actionCancelCollect(data){
    return http.get("/api/user/action/cancelCollect",data)
}

/*Api商品分类数据接口*/
// 获取商品分类详细信息
export function getGoodsType(data){
    return http.get("/api/mall/goodsType/"+data.goodsTypeId,data)
}
// 查询商品分类列表,传等级，返回对象等级数据
export function getGoodsTypeList(data){
    return http.get("/api/mall/goodsType/list",data)
}
// 依据id查询子列表
export function getGoodsTypeListChild(data){
    return http.get("/api/mall/goodsType/list/children/"+data.id,data)
}
// 商品分类层级树列表
export function getGoodsTypeTree(data){
    return http.get("/api/mall/goodsType/findTreeList",data)
}

/*商品Api接口*/
// 获取商品批发价
export function getGoodsWholesale(data){
    return http.post2("/api/mall/goods/getGoodsPrice",data)
}
// 获取商品详情
export function getGoodsDetail(data){
    return http.get("/api/mall/goods/"+data.goodsId,data)
}
// 获取商品sku列表
export function getGoodsSKUInfo(data){
    return http.get("/api/mall/goods/sku/"+data.goodsId,data)
}
// 搜索商品
export function getGoodsSearch(data){
    return http.get("/api/mall/goods/search",data)
}
// 获取商品搜索筛选列表
export function getGoodsSearchSelect(data){
    return http.get("/api/mall/goods/search/select",data)
}
// 获取优选商品筛选列表
export function getPreferredGoodsSelect(data){
    return http.get("/api/mall/goods/preferred/select",data)
}
// 获取优选商品列表
export function getPreferredGoodsList(data){
    return http.get("/api/mall/goods/list/preferred",data)
}
// 高定专栏--高定商品按类型，材质搜索
export function getCoutureGoodsList(data){
    return http.get("/api/mall/goods/getHighGoodsTypePage",data)
}
// 获取商品看了又看列表
export function getSeeAndSeeList(data){
    return http.get("/api/mall/goods/see-and-see/"+data.goodsId,data)
}
// 商品详情-相似商品
export function getSimilarityGoodsList(data){
    return http.get("/api/mall/goods/getSimilarityGoodsPage",data)
}
// 获取智能家居商品列表
export function getSmartHomeGoodsList(data){
    return http.get("/api/mall/goods/getSmartHomeGoodsList",data)
}
// 获取建材热卖商品列表
export function getBuildMaterialGoodsList(data){
    return http.get("/api/mall/goods/getBuildMaterialGoodsList",data)
}
// 高定专栏里面的类型和图片
export function getCoutureGoodsTypeCover(data){
    return http.get("/api/mall/goods/getGoodsTypeCover",data)
}
// 获取智能家居下面分类及相关商品图片
export function getSmartHomeGoodsTypeCover(data){
    return http.get("/api/mall/goods/getIntelligenceGoodsCover",data)
}

/*买家秀Api接口*/
// 获取买家秀详情
export function getBuyersShowInfo__(data){
    return http.get("/api/mall/buyersShow/"+data.buyersShowId,data)
}
// 获取买家秀列表
export function getBuyersShowList__(data){
    return http.get("/api/mall/buyersShow/list",data)
}
// 买家秀分类列表
export function getBuyersShowTypeList(data){
    return http.get("/api/mall/buyersShow/goodsTypeSelectList",data)
}
// 获取相似买家秀
export function getSimilarBuyersShowTypeList(data){
    return http.get("/api/mall/buyersShow/similar/"+data.buyersShowId,data)
}
// 订单评价-新增买家秀
export function addNewlyBuyersShow(data){
    return http.post2("/api/mall/buyersShow",data)
}

/*Api品牌信息接口*/
// 获取品牌信息
export function getBrandInfo(data){
    return http.get("/api/mall/brand/"+data.brandId,data)
}
// 获取品牌分页列表
export function getBrandPageList(data){
    return http.get("/api/mall/brand/pageList",data)
}
// 获取品牌列表
export function getBrandList(data){
    return http.get("/api/mall/brand/brandList",data)
}
// 明星品牌，品牌上新(不传值)
export function getBrandList__(data){
    return http.get("/api/mall/brand/getGoodsTypeBrand",data)
}
// 相关品牌---沙发
export function getSofaBrandList(data){
    return http.get("/api/mall/brand/getSofaTypeBrandList",data)
}
// 相关品牌---茶几-电视柜
export function getTeaOrTVBrandList(data){
    return http.get("/api/mall/brand/getTeaOrTVTypeBrandList",data)
}
// 相关品牌---床
export function getBedBrandList(data){
    return http.get("/api/mall/brand/getBedTypeBrandList",data)
}
// 智能家居热门品牌
export function getSmartHomeBrandList(data){
    return http.get("/api/mall/brand/getSmartHomeBrandList",data)
}
// 建材热门品牌
export function getBuildMaterialBrandList(data){
    return http.get("/api/mall/brand/getBuildMaterialBrandList",data)
}

/*字典数据查询*/
// 字典数据查询
export function getDictTypeList(data){
    return http.get("/api/mall/dicType/dictTypeList",data)
}

/*消息记录接口(我的消息)*/
// 查询消息记录列表
export function getMyMessageList(data){
    return http.get("/api/mall/messageLog/list",data)
}
// 删除消息记录
export function delMessage(data){
    return http.get("/api/mall/messageLog/del",data)
}

/*商品浏览记录Api接口*/
// 获取浏览记录
export function getBrowseRecordList(data){
    return http.get("/api/mall/goods/browse/list",data)
}

/*省市区县Api接口*/
// 根据条件查询所有省市区
export function getCityListAll(data){
    return http.get("/api/city/list/all",data)
}

/*收货地址Api接口*/
// 新增收货地址
export function addReceiveAddress(data){
    return http.post2("/api/mall/receiveAddress",data)
}
// 修改收货地址
export function editReceiveAddress(data){
    return http.put2("/api/mall/receiveAddress",data)
}
// 修改默认收货地址
export function editDefaultReceiveAddress(data){
    return http.put("/api/mall/receiveAddress/update/default/"+data.receiveAddressId)
}
// 获取收货地址列表
export function getReceiveAddressList(data){
    return http.get("/api/mall/receiveAddress/list",data)
}
// 删除收货地址
export function deleteReceiveAddress(data){
    return http.delete("/api/mall/receiveAddress/"+data.receiveAddressId)
}

/*Api商品随心配接口*/
// 查询商品随心配列表
export function getAtWillPackageList(data){
    return http.get("/api/mall/atWillPackage/list",data)
}
// 查询随新配套餐分类
export function getAtWillClassifyList(data){
    return http.get("/api/mall/atWillPackage/getClassilyList",data)
}
// 获取商品随心配详细信息
export function getAtWillGoodsList(data){
    return http.get("/api/mall/atWillPackage/getGoodsList",data)
}


// 获取上传参数
export function getUploadPolicy(data){
    return http.get("/api/storage/oss/policy",data)
}

/*订单Api接口*/
// 提交订单-购物车
export function submitOrder(data){
    return http.post2("/api/mall/order",data)
}
// 提交订单-批量多个商品
export function submitOrderBatch(data){
    return http.post2("/api/mall/order/batch",data)
}
// 订单列表
export function getOrderList(data){
    return http.get("/api/mall/order/list",data)
}
// 订单详情
export function getOrderDetail(data){
    return http.get("/api/mall/order/"+data.orderId,data)
}
// 上传订单支付凭证
export function upOrderVoucher(data){
    return http.post2("/api/mall/order/orderPay",data)
}
// 取消订单
export function cancelOrder(data){
    return http.put("/api/mall/order/cancel/"+data.orderId)
}

/*收款账户Api接口*/
// 获取收款账户列表
export function getReceiveAccountList(data){
    return http.get("/api/mall/receiveAccount/list",data)
}

/*网站配置接口*/
// 查询网站配置
export function getWebsiteSetting(data){
    return http.get("/api/mall/websiteSetting",data)
}
// 获取栏目集合
export function getWebsiteSettingColumn(data){
    return http.get("/api/mall/websiteSetting/columnList",data)
}
// 获取栏目页面下导航
export function getWebsiteSettingColumnGuide(data){
    return http.get("/api/mall/websiteSetting/columnGuideList",data)
}

/*【直播间】接口*/
// 查询直播间列表
export function getLiveRoomList(data){
    return http.get("/api/mall/room/list",data)
}

/*售后Api接口*/
// 新增售后申请
export function addAfterSale(data){
    return http.post2("/api/mall/AfterOrder/save",data)
}
// 查询客户售后分页列表
export function getAfterSaleList(data){
    return http.get("/api/mall/AfterOrder/list",data)
}
// 获取售后详细信息
export function getAfterSaleDetail(data){
    return http.get("/api/mall/AfterOrder/"+data.afterOrderId)
}
// 取消售后申请
export function cancelAfterSale(data){
    return http.post("/api/mall/AfterOrder/cancelAfterOrder",data)
}